export const dateMillis = {
  sec_1: 1000,
  min_1: 60 * 1000,
  hour_1: 60 * 60 * 1000,
  day_1: 24 * 60 * 60 * 1000,
  week_1: 7 * 24 * 60 * 60 * 1000,
  month_1: 30 * 24 * 60 * 60 * 1000,
  year_1: 365 * 24 * 60 * 60 * 1000,
};

export const values = {
  zeroBytes12: '0x000000000000000000000000',
  zeroBytes32: '0x0000000000000000000000000000000000000000000000000000000000000000',
  countShortNotations: ['J', 'K', 'M', 'B', 'T'],
  domains: {
    prod: 'mercle.xyz',
    staging: 'timesnap.xyz',
    getBaseDomain: () => {
      return document.location.hostname.endsWith(values.domains.prod) ? values.domains.prod : values.domains.staging;
    },
    getBackendSubdomain: () => {
      return document.location.hostname.endsWith(values.domains.prod) ? 'apipreprod' : 'apisit';
    },
  },
};

export const resources = {
  img: {
    getAvatar: address => `https://effigy.im/a/${address}`,

    eventTags: {
      nft: '/eventTags/lootbox.svg',
      xp: `/eventTags/xp.svg`,
    },

    ipfs: ipfsUrl => {
      if (!ipfsUrl) return undefined;
      const cid = ipfsUrl?.indexOf('//') > -1 ? ipfsUrl.split('//')[1] : ipfsUrl;
      return `https://resolvesit.mercle.xyz/ipfs/${cid}`;
    },
    twitter: 'https://dskhnex0aho1n.cloudfront.net/public/x.svg',
    twitter_no_circle: 'https://dskhnex0aho1n.cloudfront.net/public/twitter.svg',
    telegram: 'https://app.mercle.xyz/icon/telegram.svg',
    facebook: 'https://app.mercle.xyz/icon/facebook.svg',
    copywhite: 'https://app.mercle.xyz/icon/copyWhite.svg',
  },
  links: {
    referralLink: referralCode => `${document.location.host}/r/${referralCode}`,
    s3: {
      prod: 'storage-mercle-prod',
      staging: 'storage-daohook-staging',
      getS3Bucket: () => {
        return document.location.hostname.endsWith(values.domains.prod) ? resources.links.s3.prod : resources.links.s3.staging;
      },
      getS3Link: () => `https://${resources.links.s3.getS3Bucket()}.s3.amazonaws.com`,
    },
    oauth: {
      redirectBackend: () => `${apis.backend._baseUrl}/login`,
    },
    twitter: {
      userPage: handle => `https://twitter.com/intent/follow?screen_name=${handle}`,
      like: tweet_id => `https://twitter.com/intent/like?tweet_id=${tweet_id}`,
      retweet: tweet_id => `https://twitter.com/intent/retweet?tweet_id=${tweet_id}`,
      tweet: text => `https://twitter.com/intent/tweet?text=${text}`,
    },
    telegram: {
      userPage: pageId => `https://web.telegram.org/z/#${pageId}`,
      mercleGroup: 'https://t.me/mercleofficial',
    },
    uniswap: {
      swap: (tokenAddress, quantity) => `https://app.uniswap.org/#/swap?exactField=output&exactAmount=${quantity}&outputCurrency=${tokenAddress}`,
      liquidityAdd: (tokenAddress1, tokenAddress2) => `https://app.uniswap.org/#/add/v2/${tokenAddress1}/${tokenAddress2}`,
    },
    snapshot: {
      space: spaceId => `https://snapshot.org/#/${spaceId}`,
    },
    opensea: (chainId, contractAddr, tokenId) => {
      let prefix = {
        1: `https://opensea.io/assets/ethereum`,
        10: `https://opensea.io/assets/optimism`,
        137: `https://opensea.io/assets/matic`,
        169: `https://element.market/assets/manta-pacific`,
        8453: 'https://opensea.io/assets/base',
        42161: `https://opensea.io/assets/arbitrum`,
        59144: `https://linea.nftscan.com`,
        534352: `https://element.market/assets/scroll`,
        5: `https://testnets.opensea.io/assets/goerli`,
      };
      if (prefix?.[chainId]) return `${prefix?.[chainId]}/${contractAddr}/${tokenId}`;
      return undefined;
    },
  },
};

export const chainIdToNativeTokenSymbol = {
  1: 'ETH',
  5: 'GETH',
  10: 'ETH',
  56: 'BNB',
  100: 'xDai',
  137: 'MATIC',
  169: 'ETH',
  5000: 'MNT',
  8453: 'ETH',
  42161: 'ETH',
  43114: 'AVAX',
  534352: 'ETH',
};
export const cosmosMapping = {
  'xion-testnet-1': 99999901,
};
export const cosmosChains = [
  {
    id: 99999901,
    chainId: 'xion-testnet-1',
    name: 'Xion',
    nativeCurrency: {
      name: 'Xion',
      symbol: 'xion',
      decimals: 6,
    },
    blockExplorers: {
      default: {
        name: 'Burnt Explorer',
        url: 'https://explorer.burnt.com/xion-testnet-1/',
      },
    },
  },
];

export const rpc = {
  1: 'https://eth-mainnet.g.alchemy.com/v2/2P-Dl55AC-wRfFXnKxUN9HKZqur8-cnW',
  5: 'https://eth-goerli.g.alchemy.com/v2/2P-Dl55AC-wRfFXnKxUN9HKZqur8-cnW',
  10: 'https://wider-light-sea.optimism.quiknode.pro/f3f07ca6955a0c1242a31dee2d0db257c0faccef',
  56: 'https://rpc.ankr.com/bsc',
  100: 'https://rpc.gnosischain.com',
  137: 'https://polygon-rpc.com',
  169: 'https://pacific-rpc.manta.network/http',
  5000: 'https://rpc.mantle.xyz',
  8453: 'https://base-mainnet.g.alchemy.com/v2/2P-Dl55AC-wRfFXnKxUN9HKZqur8-cnW',
  42161: 'https://distinguished-light-bird.arbitrum-mainnet.quiknode.pro/c8906c405c70519e87322d075d14044cb358b6fe',
  421614: 'https://sepolia-rollup.arbitrum.io/rpc',
  43114: 'https://api.avax.network/ext/bc/C/rpc',
  59144: 'https://linea-mainnet.infura.io/v3/fdce7fbe1a7f493ea064f7a1a21bac40',
  534352: 'https://rpc.scroll.io',
  810180: 'https://rpc.zklink.io',
  81457: 'https://rpc.blast.io',
};

export const deployed = {
  MintWithClaim: {
    1: '0xDB38099BcBB735C98CC5F18f8ac47637Cf0D72e1',
    10: '0xB1e3E1310194e97D4aE1e32889df6E78aF3735D1',
    56: '0xEb1bE1eEE61df1D95d353739E4B2bD5c949b3595',
    137: '0x0D62cCDa3dB4E1418c3a5CF276Eacb1f90E121aE',
    169: '0x98B47fd0146D9869C1f61B69C555FD7ADC018032',
    5000: '0xDB38099BcBB735C98CC5F18f8ac47637Cf0D72e1',
    8453: '0xDF02908bC34E40aCA9f9aecc963bc496e4432f20',
    42161: '0x6aFE5df19ed8e0A0FA1347D8810530CC871f45A2',
    43114: '0x4E8A646798316a9Ad699B870284206E62efb79e1',
    59144: '0xDB38099BcBB735C98CC5F18f8ac47637Cf0D72e1',
    534352: '0xDB38099BcBB735C98CC5F18f8ac47637Cf0D72e1',
    810180: '0xb2e878e9F23947fc395D388756FCe1e9B089fFE4', //TODO: deploy new MWC on zklink
    81457: '0x98B47fd0146D9869C1f61B69C555FD7ADC018032',
    99999901: 'xion1zp64n3n5ukw2dcfzncc9wmym7uwn92zqf773znhuk43ptc3xmzysfp5mqc',
  },
  mercle: {
    Treasury: {
      eoa: '0xC5d5846b013D7929dAC053d4030b00047A14DDE3',
    },
    MembershipNFTFactory: {
      1: '0x9fDAAA5C2281AABC4E9Ea0654bD278390d4D1513',
      10: '0x20E96f8178176D0635EB38ED7Acb28226Efa3bBa',
      56: '0xF9BF5aE3D3e1Dbb1c9429dFFe3e3e6e7e6687f4e',
      137: '0x21C20bc08C31b1b3707d511c9Ac8Bb7804DB45Cc',
      169: '0x9fDAAA5C2281AABC4E9Ea0654bD278390d4D1513',
      5000: '0x98B47fd0146D9869C1f61B69C555FD7ADC018032',
      8453: '0xc96b472c7957A6cFB26815B5e8e0D8db1af77C5A',
      42161: '0x6288342da7A531E09e4a4Ed117AD8D301dad41CB',
      43114: '0xDB38099BcBB735C98CC5F18f8ac47637Cf0D72e1',
      59144: '0x9fDAAA5C2281AABC4E9Ea0654bD278390d4D1513',
      31337: '0x5FbDB2315678afecb367f032d93F642f64180aa3', // localhost
      534352: '0x9fDAAA5C2281AABC4E9Ea0654bD278390d4D1513',
      810180: '0x2055B951cdD54ef14C217C0E57f8e654f80c1636', //TODO: deploy new factory contract on zklink
      81457: '0x9fDAAA5C2281AABC4E9Ea0654bD278390d4D1513',
    },
    Forwarder: {
      10: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      56: '0x80A6b788F6DCA8309909409c4c5F8688241de420',
      137: '0x73769CB6055c9AD8FD69643838A911213B3AF2bd',
      169: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      8453: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      42161: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      43114: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      59144: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      534352: '0x059e101A4CfaDc1cc06966130006AB588e912899',
      5: '0x265E658C9aF6d2Fc333742426ff416EA67FEA0B5',
      81457: '0x059e101A4CfaDc1cc06966130006AB588e912899',
    },
    ClaimIssuer: {
      1: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      10: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      56: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      137: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      169: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      5000: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      8453: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      42161: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      43114: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      59144: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      534352: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',
      81457: '0xEab1B5DA84A5CCB932ffF583685D1cF3828699Dd',

      5: '0xeCE207e717365d67f87e1aE5cAc715Cf60208e63',
    },
  },
};
export const alchemy = { apiKey: '2P-Dl55AC-wRfFXnKxUN9HKZqur8-cnW' };

export const apis = {
  gelato: {
    baseapi: {
      api: 'https://api.gelato.digital',
    },
    getTxn: taskId => `${apis.gelato.baseapi.api}/tasks/status/${taskId}`,
  },
  alchemy: {
    baseapi: {
      1: 'https://eth-mainnet.g.alchemy.com/nft/v2/vma47TKOLkZ-xH_XQh1tQFfLADEJiHQt',
      5: 'https://eth-goerli.g.alchemy.com/nft/v2/vma47TKOLkZ-xH_XQh1tQFfLADEJiHQt',
      137: 'https://polygon-mainnet.g.alchemy.com/nft/v2/vma47TKOLkZ-xH_XQh1tQFfLADEJiHQt',
      8453: 'https://base-mainnet.g.alchemy.com/nft/v2/vma47TKOLkZ-xH_XQh1tQFfLADEJiHQt',
    },
    baseapiAdminAnalytics: {
      1: `https://eth-mainnet.g.alchemy.com/v2/${alchemy.apiKey}`,
      5: `https://eth-goerli.g.alchemy.com/v2/${alchemy.apiKey}`,
      137: `https://polygon-mainnet.g.alchemy.com/v2/${alchemy.apiKey}`,
      8453: `https://base-mainnet.g.alchemy.com/v2/${alchemy.apiKey}`,
    },
    getNftTokenId: (ownerAddress, tokenAddress, chainId, withMetadata = false) =>
      `${apis.alchemy.baseapi[chainId]}/getNFTs?owner=${ownerAddress}&pageSize=100&contractAddresses\[\]=${tokenAddress}&withMetadata=${withMetadata}`,
    getNFTMetadata: (tokenAddress, tokenId, chainId) => `${apis.alchemy.baseapi[chainId]}/getNFTMetadata?contractAddress=${tokenAddress}&tokenId=${tokenId}&refreshCache=false`,
    getMintHistory: chainId => `${apis.alchemy.baseapiAdminAnalytics[chainId]}`,
    getOwnersForCollection: (tokenAddress, chainId) => `${apis.alchemy.baseapi[chainId]}/getOwnersForCollection?contractAddress=${tokenAddress}&withTokenBalances=true`,
    getNftMetaDataBatch: chainId => `${apis.alchemy.baseapi[chainId]}/getNFTMetadataBatch`,
    getOwnersForToken: (tokenAddress, tokenId, chainId) => `${apis.alchemy.baseapi[chainId]}/getOwnersForToken?contractAddress=${tokenAddress}&tokenId=${tokenId}`,
    getTransfers: chainId => `${apis.alchemy.baseapiAdminAnalytics[chainId]}`,
  },
  backend: {
    login: () => `${apis.backend._baseUrl}/login`,
    logout: () => `${apis.backend._baseUrl}/login/logout`,
    refreshAccessToken: () => `${apis.backend._baseUrl}/login/refresh`,
    getLoginState: () => `${apis.backend._baseUrl}/login/loginState`,
    loginGetCookies: () => `${apis.backend._baseUrl}/login/cookies`,
    disconnectAccount: () => `${apis.backend._baseUrl}/user/disconnectAccount`,
    //cosmos
    xionTokenId: (tokenAddress, xionAddress) => `${apis.backend._baseUrl}/cosmos/getXionTokenId?tokenAddress=${tokenAddress}&address=${xionAddress}`,

    // user
    getUserIdentityClaims: () => `${apis.backend._baseUrl}/user/identity/metadata`,
    getUserStats: () => `${apis.backend._baseUrl}/user/reward-stats`,
    getUserActivity: () => `${apis.backend._baseUrl}/user/claim-activity-graph`,
    checkUserName: username => `${apis.backend._baseUrl}/user/filter?type=name&value=${username}`,
    setUserName: () => `${apis.backend._baseUrl}/user`,
    getReferralCode: url => `${apis.backend._baseUrl}/referral/code?url=${url}`,
    getReferalRedirectUrl: code => `${apis.backend._baseUrl}/referral/getRedirectUrl/?code=${code}`,
    getReferralStats: () => `${apis.backend._baseUrl}/referral/referral-stats`,
    useReferralCode: () => `${apis.backend._baseUrl}/referral/use-code`,
    getClaimPayload: currency => `${apis.backend._baseUrl}/referral/claim-payload?currency=${currency}`,
    trackExtraReferral: code => `${apis.backend._baseUrl}/referral/track-extra-referrals?referral=${code}`,
    getNftProfiles: () => `${apis.backend._baseUrl}/user/nft-profiles`,
    getTotalMewPoints: data => `${apis.backend._baseUrl}/user/getMewpoints/${data.userId}/${data.type}`,
    getLatestMewPointEntry: data => `${apis.backend._baseUrl}/user/getLatestMewPointEntry/${data.userId}/${data.type}`,
    addMewPointEntry: () => `${apis.backend._baseUrl}/user/addMewPointEntry`,
    //hos
    getAllHOSStats: () => `${apis.backend._baseUrl}/user/hos`,
    getHOSCommunityData: level => `${apis.backend._baseUrl}/user/hos/campaign-from-level?userLevel=${level}`,
    getOgNftEligibilityData: () => `${apis.backend._baseUrl}/user/og-nft/eligibility-data`,
    OgTrackingData: () => `${apis.backend._baseUrl}/user/og-nft/track`,

    // community
    getCollection: (communityName, tokenAddress, campaignName) =>
      `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}?tokenAddress=${tokenAddress}`,
    getCommunity: communityName => `${apis.backend._baseUrl}/community/${communityName}`,
    topCommunities: (tag = 'community', skip = 0, limit = 10, getAll) =>
      `${apis.backend._baseUrl}/community/top?tag=${tag}&skip=${skip}&limit=${limit}${getAll ? `&getAll=true` : ''}`,
    get_communitiesForTokens: () => `${apis.backend._baseUrl}/community/getForTokens`,
    getReferralCommunityData: referralCode => `${apis.backend._baseUrl}/referral/community?referral=${referralCode}`,
    put_uploadFileV2: () => `${apis.backend._baseUrl}/misc/uploadFile`,
    get_fileType: fileUrl => `${apis.backend._baseUrl}/misc/fileType?fileUrl=${encodeURIComponent(fileUrl)}`,
    initializeCollection: (communityName, campaignName) => `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}/preTokenDeploy`,
    trackTokenCreation: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/trackTokenCreation`,
    createOffchainToken: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/createOffchainToken`,
    post_createCommunity: () => `${apis.backend._baseUrl}/community`,
    put_updateLinks: communityName => `${apis.backend._baseUrl}/community/${communityName}/settings/links`,
    put_uploadFileAdmin: communityName => `${apis.backend._baseUrl}/community/${communityName}/settings/upload/file`,
    post_defaultJoinChannel: communityName => `${apis.backend._baseUrl}/community/${communityName}/discord/setup/defaultJoinChannel`,
    // event
    allEvents: (skip = 0, limit = 10) => `${apis.backend._baseUrl}/community/allevents?skip=${skip}&limit=${limit}`,
    getEvents: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/event`,
    savePfpImage: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/profile/selectInventory?tokenAddress=${tokenAddress}`,
    verifyTask: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/rewards/claim?tokenAddress=${tokenAddress}`,

    // campaign
    getCampaign: (communityName, tokenAddress, campaignName) => `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}?tokenAddress=${tokenAddress}`,

    // mint nft
    getMintToken: (communityName, tokenAddress, campaignName, userAddress, username, referralCode, offChainToOnChain) => {
      let params = [];
      if (tokenAddress) params.push(`tokenAddress=${tokenAddress}`);
      if (userAddress) params.push(`recipient=${userAddress}`);
      if (username) params.push(`username=${username}`);
      if (referralCode) params.push(`referral=${referralCode}`);
      if (offChainToOnChain) params.push(`offChainToOnChain=${offChainToOnChain}`);
      return `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}/mint?${params.join('&')}`;
    },
    getSubmissionTasks: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/adminVerification/all`,
    verifySubmissionTasks: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/adminVerification/verify`,
    getUserSubmittedTaskData: (communityName, taskId) => `${apis.backend._baseUrl}/community/${communityName}/tokens/adminVerification/myTask?taskId=${taskId}`,
    getUserSubmittedResults: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/adminVerification/my`,
    post_submitVerifyData: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/adminVerification/submit?tokenAddress=${tokenAddress}`,
    post_adminIssueReward: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/adminVerification/issueReward?tokenAddress=${tokenAddress}`,
    getUserStats: () => `${apis.backend._baseUrl}/user/reward-stats`,
    getMintStats: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/mintStats?tokenAddress=${tokenAddress}`,
    getTasksDoneStats: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/tasksDoneStats?tokenAddress=${tokenAddress}`,
    getTasksDone: (communityName, tokenAddress, searchText, page, refreshCache, exportCsv) => {
      const params = [`tokenAddress=${tokenAddress}`];
      if (searchText) params.push(`searchText=${searchText}`);
      if (page) params.push(`page=${page}`);
      if (refreshCache) params.push(`page=${refreshCache}`);
      if (exportCsv) params.push(`exportCsv=${exportCsv}`);
      return `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/tasksDone?${params.join('&')}`;
    },
    getTasksSummary: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/tasksSummary?tokenAddress=${tokenAddress}`,
    getTaskBreakdown: (communityName, tokenAddress, taskId, page) =>
      `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/taskBreakdown?tokenAddress=${tokenAddress}&taskId=${taskId}&page=${page}`,
    getUsersSummary: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/usersSummary?tokenAddress=${tokenAddress}`,
    getUserBreakdown: (communityName, tokenAddress, tokenId, userAddress, page) =>
      `${apis.backend._baseUrl}/community/${communityName}/tokens/event/analytics/userBreakdown?tokenAddress=${tokenAddress}&tokenId=${tokenId}&userAddress=${userAddress}&page=${page}`,

    // nft details
    getNftProfile: (communityName, tokenAddress, tokenId) =>
      `${apis.backend._baseUrl}/community/${communityName}/tokens/rewardsNft/metadata?tokenAddress=${tokenAddress}&tokenId=${tokenId}`,
    getOffchainTokenDetails: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/rewardsNft/offchain/profile?tokenAddress=${tokenAddress}`,
    getOnChainTokenDetails: (communityName, tokenAddress, chainId) =>
      `${apis.backend._baseUrl}/community/${communityName}/tokens/rewardsNft/onchain/profile?tokenAddress=${tokenAddress}&chainId=${chainId}`,
    getOffchainTokenId: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/rewardsNft/tokenIdOffchain?tokenAddress=${tokenAddress}`,

    // task actions
    discordAssignRole: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/discord/tryAssignRole?tokenAddress=${tokenAddress}`,
    get_redirectToJoinDiscordUrl: (communityName, guildId, channelId) => {
      let params = [];
      if (guildId) params.push(`guildId=${guildId}`);
      if (channelId) params.push(`channelId=${channelId}`);
      return `${apis.backend._baseUrl}/community/${communityName}/discord/guilds/join?${params.join('&')}`;
    },
    get_defaultJoinChannel: communityName => `${apis.backend._baseUrl}/community/${communityName}/discord/setup/defaultJoinChannel`,
    get_guildChannels: communityName => `${apis.backend._baseUrl}/community/${communityName}/discord/guilds/channels`,
    get_discordBotIntegrationUrl: communityName => `${apis.backend._baseUrl}/community/${communityName}/discord/setup/discordBotIntegrationUrl`,
    redirectTaskConfirm: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/redirectTaskSubmissions/submit?tokenAddress=${tokenAddress}`,

    // token creation
    trackTokenCreation: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/trackTokenCreation`,
    createOffchainToken: communityName => `${apis.backend._baseUrl}/community/${communityName}/tokens/createOffchainToken`,

    // raffles
    get_allRaffle: () => `${apis.backend._baseUrl}/raffle/all`,
    get_allBanners: () => `${apis.backend._baseUrl}/raffle/allBanners`,
    get_raffles: (isLive = true, skip = 0, limit = 4, communityName) =>
      `${apis.backend._baseUrl}/raffle/all?limit=${limit}&skip=${skip}&live=${isLive}${communityName ? `&communityName=${communityName}` : ''}`,
    get_singleRaffle: raffleName => `${apis.backend._baseUrl}/raffle/${raffleName}`,
    get_raffleTaskCompleteStatus: raffleName => `${apis.backend._baseUrl}/raffle/${raffleName}/taskCompleteStatus`,
    get_raffleTicketForUser: raffleName => `${apis.backend._baseUrl}/raffle/${raffleName}/getRaffleTicketsForUser`,
    post_raffleEntry: raffleName => `${apis.backend._baseUrl}/raffle/${raffleName}/raffleEntry`,
    post_rafflePickWinner: (raffleName, txnHash) => `${apis.backend._baseUrl}/raffle/${raffleName}/pickWinner?txnHash=${txnHash}`,
    get_raffleEntriesIFPS: raffleName => `${apis.backend._baseUrl}/raffle/${raffleName}/getRaffleEntriesIPFS`,
    get_raffleClaimReward: raffleName => `${apis.backend._baseUrl}/raffle/${raffleName}/claimReward`,
    //Quiz
    getQuiz: (communityName, campaignName, quizId) => `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}/quiz?quizId=${quizId}`,
    getQuizResult: (communityName, campaignName, quizId) => `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}/quizResult?quizId=${quizId}`,
    post_submitQuizAnswer: (communityName, campaignName) => `${apis.backend._baseUrl}/community/${communityName}/tokens/campaign/${campaignName}/quizResult`,
    // mercle
    getMercleDiscordInvite: () => `${apis.backend._baseUrl}/misc/mercleDiscordInvite`,

    test: {
      resetMyNftProfile: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/resetNftAndUserData?tokenAddress=${tokenAddress}`,
      deleteMyNftProfile: (communityName, tokenAddress) => `${apis.backend._baseUrl}/community/${communityName}/tokens/event/deleteNftProfile?tokenAddress=${tokenAddress}`,
    },
    getAnalyticsData: () => `${apis.backend._baseUrl}/misc/analytics`,

    _baseUrl: `https://${values.domains.getBackendSubdomain()}.${values.domains.getBaseDomain()}`,
    // _baseUrl: `http://localhost:3001`,
    // _baseUrl: 'https://apipreprod.mercle.xyz',
  },
};

export const MBADGE_LEAGUE = [
  { name: 'NONE', mbadges: 0, defiXP: 0, image: 'https://dskhnex0aho1n.cloudfront.net/public/sad.svg' },
  { name: 'SILVER', mbadges: 3, defiXP: 90, image: 'https://dskhnex0aho1n.cloudfront.net/public/silverbar.svg' },
  { name: 'GOLD', mbadges: 15, defiXP: 450, image: 'https://dskhnex0aho1n.cloudfront.net/public/goldbar.svg' },
  { name: 'DIAMOND', mbadges: 40, defiXP: 1000, image: 'https://dskhnex0aho1n.cloudfront.net/public/diamondbar.svg' },
];

export const SNAPSHOT_MILLIS = 1719792000000;

export const types = {
  MercleOffchain: 9991999,
  loginVia: {
    discord: 'discord',
    email: 'email',
    twitter: 'twitter',
    telegram: 'telegram',
    xionAddress: 'xionAddress',
  },
  postLoginActions: {
    none: undefined,
    joinDiscord: 'joinDiscord',
    followTwitter: 'followTwitter',
    joinTelegram: 'joinTelegram',
    subNewsletter: 'subNewsletter',
    loginInstagram: 'loginInstagram',
    admins: {
      addDiscordBot: 'admins_addDiscordBot',
      createCommunity: 'admins_createCommunity',
    },
  },
  fileUploadTask: {
    avatar: 'avatar',
    banner: 'banner',
    markdown: 'markdown',
    // this is the resouce like image, video etc
    nftResource: 'nftresource',
    // this is the nft json
    nftJson: 'nftjson',
  },
  token: {
    erc20: 'erc20',
    erc721: 'erc721',
    offchain: 'offchain',
  },
  SkillTypes: {
    Level: 'Level',
    Xp: 'XP',
    Social: 'Social',
    'De-Fi': 'De-Fi',
    Community: 'Community',
  },
  ExtraReferralTypes: {
    signin: 'sign_in',
  },
  EventTypes: {
    personalized: 'personalized',
    raffle: 'raffle',
  },
  wallet: {
    safe: 'safe',
    wallet: 'wallet',
  },
  EventTypes: {
    personalized: 'personalized',
    raffle: 'raffle',
  },
  wallet: {
    safe: 'safe',
    wallet: 'wallet',
  },
};

export const typesComplex = {
  EventTypes: {
    ConnectDiscord: { name: 'connectDiscord', type: types.SkillTypes.Social },
    ConnectTwitter: { name: 'connectTwitter', type: types.SkillTypes.Social },
    ConnectTelegram: { name: 'connectTelegram', type: types.SkillTypes.Social },
    HoldToken: { name: 'holdToken', type: types.SkillTypes['De-Fi'] },
    HoldNFT: { name: 'holdNFT', type: types.SkillTypes['De-Fi'] },
    SnapshotVote: { name: 'snapshotVote', type: types.SkillTypes.Community },
    SnapshotCreateProposal: { name: 'snapshotCreateProposal', type: types.SkillTypes.Community },
    GithubPrOrg: { name: 'githubPrOrg', type: types.SkillTypes.Community },
    GithubPr: { name: 'githubPr', type: types.SkillTypes.Community },
    PoapEvent: { name: 'poapEvent', type: types.SkillTypes.Community },
    MailList: { name: 'mailList', type: types.SkillTypes.Social },
    UniswapLP: { name: 'uniswapLP', type: types.SkillTypes['De-Fi'] },
    PushSubChannel: { name: 'pushSubChannel', type: types.SkillTypes.Social },
    StakedInThorSaver: { name: 'stakedInThorSaver', type: types.SkillTypes['De-Fi'] },
    TwitterRetweet: { name: 'twitterRetweet', type: types.SkillTypes.Social },
    TwitterTweet: { name: 'twitterTweet', type: types.SkillTypes.Social },
    TwitterLikeAndRetweet: { name: 'twitterLikeAndRetweet', type: types.SkillTypes.Social },
    ExternalLink: { name: 'externalLink', type: types.SkillTypes.Community },
    DiscordStageAttend: { name: 'discordStageAttend', type: types.SkillTypes.Community },
    DiscordHasRole: { name: 'discordHasRole', type: types.SkillTypes.Community },
    Quiz: { name: 'quiz', type: types.SkillTypes.Community },
    RegistrationForm: { name: 'registrationFrom', type: types.SkillTypes.Social },
    LifiBridge: { name: 'lifiBridge', type: types.SkillTypes['De-Fi'] },
    LifiTxnLeaderboard: { name: 'lifiTxnLeaderboard', type: types.SkillTypes['De-Fi'] },
    DaoWallet: { name: 'daoWallet', type: types.SkillTypes.Community },
    HasTransferedAsset: { name: 'hasTransferedAsset', type: types.SkillTypes['De-Fi'] },
    ClaimTokenReward: { name: 'claimTokenReward', type: types.SkillTypes.Social },
    GitcoinPassportScore: { name: 'gitcoinPassportScore', type: types.SkillTypes.Community },
    HasContractInteraction: { name: 'hasContractInteraction', type: types.SkillTypes['De-Fi'] },
    AdminVerification: { name: 'adminVerification', type: types.SkillTypes['De-Fi'] },
    InstagramCaption: { name: 'instagramCaption', type: types.SkillTypes.Social },
    RedirectTask: { name: 'redirectTask', type: types.SkillTypes.Social },
    OnRamp: { name: 'onRamp', type: types.SkillTypes['De-Fi'] },
    TwitterBio: { name: 'twitterBio', type: types.SkillTypes?.Community },
    JoinGuild: { name: 'joinGuild', type: types.SkillTypes?.Community },
    TwitterName: { name: 'twitterName', type: types.SkillTypes?.Community },
    DiscordMessageCount: { name: 'discordMessageCount', type: types.SkillTypes?.Community },
    DiscordNFTRoleMember: { name: 'discordNFTRoleMember', type: types.SkillTypes?.['De-Fi'] },
    DiscordAssignRole: { name: 'discordAssignRole', type: types.SkillTypes?.Social },
    GnarsUpload: { name: 'gnarsUpload', type: types.SkillTypes?.['Content-Creation'] },
    YoutubeSubscribeChannel: { name: 'youtubeSubscribeChannel', type: types.SkillTypes.Social },
    ReferralLink: { name: 'referralLink', type: types.SkillTypes.Community },
    MintProfile: { name: 'mintProfile', type: types.SkillTypes.Community },
    PowerloomVerify: { name: 'powerloomVerify', type: types.SkillTypes['De-Fi'] },
    PowerloomKyc: { name: 'powerloomKyc', type: types.SkillTypes.Social },
    ReferralVerify: { name: 'referralVerify', type: types.SkillTypes.Community },
    OnSiteAttendance: { name: 'onSiteAttendance', type: types.SkillTypes.Community },
  },
};

export const id = {
  storage: {
    loginVia: 'loginVia',
    userStats: 'user_stats',
    nftProfiles: 'nft-profiles',
    community: communityName => `community:${communityName}`,
    communityRaffle: communityName => `community_raffles:${communityName}`,

    allEvents: 'allEvents',
    event: communityName => `v2_event_${communityName}`,

    campaign: campaignName => `campaign_${campaignName}`,
    tokenSymbol: (address, chainId) => `tokenSymbol:${chainId}:${address}`,
    tokenDecimals: (address, chainId) => `tokenDecimals:${chainId}:${address}`,

    nftProfile: (ownerAddress, tokenAddress, chainId) => `nft_profile_${ownerAddress}_${tokenAddress}_${chainId}`,

    referralCode: `referralCode`,
    mintFeeTxnHash: commId => `mintTxnHash_${commId}`,
    attendanceCode: 'attendanceCode',
  },
};

export const chainexplorer = {
  1: (a, isTxn = false) => `https://etherscan.io/${!isTxn ? 'address' : 'tx'}/${a}`,
  3: (a, isTxn = false) => `https://ropsten.etherscan.io/${!isTxn ? 'address' : 'tx'}/${a}`,
  5: (a, isTxn = false) => `https://goerli.etherscan.io/${!isTxn ? 'address' : 'tx'}/${a}`,
  10: (a, isTxn = false) => `https://optimistic.etherscan.io/${!isTxn ? 'address' : 'tx'}/${a}`,
  42: (a, isTxn = false) => `https://kovan.etherscan.io/${!isTxn ? 'address' : 'tx'}/${a}`,
  56: (a, isTxn = false) => `https://bscscan.com/${!isTxn ? 'address' : 'tx'}/${a}`,
  100: (a, isTxn = false) => `https://blockscout.com/xdai/mainnet/${!isTxn ? 'address' : 'tx'}/${a}`,
  137: (a, isTxn = false) => `https://polygonscan.com/${!isTxn ? 'address' : 'tx'}/${a}`,
  169: (a, isTxn = false) => `https://pacific-explorer.manta.network/${!isTxn ? 'address' : 'tx'}/${a}`,
  250: (a, isTxn = false) => `https://ftmscan.com/${!isTxn ? 'address' : 'tx'}/${a}`,
  1101: (a, isTxn = false) => `https://zkevm.polygonscan.com/${!isTxn ? 'address' : 'tx'}/${a}`,
  5000: (a, isTxn = false) => `https://explorer.mantle.xyz/${!isTxn ? 'address' : 'tx'}/${a}`,
  8453: (a, isTxn = false) => `https://basescan.org/${!isTxn ? 'address' : 'tx'}/${a}`,
  42161: (a, isTxn = false) => `https://arbiscan.io/${!isTxn ? 'address' : 'tx'}/${a}`,
  42220: (a, isTxn = false) => `https://explorer.celo.org/mainnet/${!isTxn ? 'address' : 'tx'}/${a}`,
  43114: (a, isTxn = false) => `https://avascan.info/blockchain/xana/${!isTxn ? 'address' : 'tx'}/${a}`,
  59144: (a, isTxn = false) => `https://explorer.linea.build/${!isTxn ? 'address' : 'tx'}/${a}`,
  534352: (a, isTxn = false) => `https://scrollscan.com/${!isTxn ? 'address' : 'tx'}/${a}`,
};

export default { dateMillis, rpc, types, typesComplex, deployed, values, resources, apis, id };
